export const de = {
  // navbar
  home: "Startseite",
  menu: "Speisekarte",
  contact: "Kontakt",
  // home
  home_quote:
    'Seit Herbst 2013 bieten wir Dir, neben anderen interessanten Gerichten, das klassische peruanische Ceviche in unserem kleinen, entspannten Restaurant, gelegen zwischen Oranienplatz und Kottbusser Tor, im Herzen von Kreuzberg.\n Mit viel Liebe zum Detail nehmen wir Dich mit auf eine unverwechselbare Geschmacksreise durch die Vielfalt der peruanischen Küche.',
  // footer and contact
  timetable: "Öffnungszeiten",
  from_monday: "Von Montag bis Sontag: ab 18 Uhr",
  sunday: "Sonntags geschlossen",
  follow_us: "Folge uns",
  // menu page
  menu_title: "SPEISEKARTE",
  starters: "VORSPEISEN",
  papas: "Kartoffeln oder frittierter Maniok mit Huancaina Sauce (Vegetarisch)",
  salmorejo: "Salmorejo vegan oder mit Mojama und hartgekochtem Ei",
  pallares: "Lima-Bohnen-Salat mit Orangenvinaigrette und frittiertem Rucola",
  ensalada: "Beluga-Linsen-Salat mit Fenchel und Avocado-Creme",
  tiradito: "Fisch-Carpaccio mit Granatapfel-Tigermilch und Mandelblättchen",
  tigre: "Tigermilch mit Fisch und Meeresfrüchten",

  hot_dishes: "WARMES ESSEN",
  locro: "Peruanischer Kürbiseintopf mit Kürbiskernen",
  queso: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 - mit Ziegenkäse",
  sopa: "Fischsuppe mit Meeresfrüchten",
  tataki: "Thunfisch-Tataki mit gegrilltem Kartoffeln, eingelegter Zwiebel und Ajipanca",
  pulpo: "Gegrillter Oktopus mit frittiertem Maniok, Babyspinat und Limabohnenpüree",

  cevi_vegano: "Veganes Ceviche (Gemüse und Früchte der Saison)",
  cevi_pescado: "Fisch-Ceviche",
  cevi_mixto: "Gemischtes Ceviche mit Fisch, Garnelen und Tintenfisch",
  cevi_pulpo: "Oktopus und Fisch-Ceviche",
  cevi_nikkei: "Thunfisch-Ceviche mit Avocado und Nori-Algen",

  desserts: "NACHTISCH",
  suspiro: "Lima-Seufzer (Toffee-Crème mit Merengue)",
  sueño: "Maracuya-Traum (Maracuya-Crème, Crumble und Merengue)",

  drink_title: "GETRÄNKE",
  white_whine: "WEIßWEIN",
  red_whine: "ROTWEIN",
  beer: "BIER",
  long_drinks: "LONGDRINKS",
  soft_drinks: "SOFTDRINKS",
  coffee: "KAFFEE UND TEE",
  cocktails: "PISCO-COCKTAILS",

  bottle: "Flasche",
  juices: "Apfel/Orange/Ananas",
  water: "WASSER",
  gas: "Laut oder leise",
  tee: "Verschiedene Teesorten",

  no_alcohol: "Lammsbräu alcoholfrei",
  
  additives: "Zusatzstoffe",
  caffeine: "Koffein",
  eggwhite: "Eiweiß",
  colouring: "Farbstoff",
  sulphites: "Sulfite",

  // contact page
  data:"Daten",
  write_us: "Schreibe uns",
  contact_title: "KONTAKT",
  name: "Name",
  name_placeholder: "Dein Name",
  email: "E-Mail Adresse",
  email_placeholder: "Deine E-Mail Adresse",
  telephone: "Telefon",
  telephone_placeholder: "Deine Telefonnummer",
  message: "Nachricht",
  submit: "Senden",
  confirmation_Message: "Vielen Dank für deine Nachricht.",
};
