export const es = {
  // navbar
  home: "Inicio",
  menu: "Menú",
  contact: "Contacto",
  // home
  home_quote:
    `Desde otoño de 2013, La Cevicheria te ofrece, entre muchas otras interesantes opciones, el ceviche en un ambiente peruano y familiar en nuestro pequeño restaurante entre Oranienplatz y Kottbusser Tor, en el corazón de Kreuzberg. \n Cuidando los detalles y la identidad peruana te llevaremos en un viaje de sabores por la incomparable variedad de esta cocina única.`,
  // footer and contact
  timetable: "Horario",
  from_monday: "De Lunes a Domingo: a partir de las 18h",
  sunday: "Domingos Cerrados",
  follow_us: "Síguenos",
  // menu page
  menu_title: "MENÚ",
  starters: "ENTRADAS",
  papas: "Papas o yuca frita a la Huancaína (vegetariano)",
  salmorejo: "Salmorejo vegano o con Mojama y huevo duro picado",
  pallares: "Ensalada de pallares con vinagreta de naranja y rúcula frita",
  ensalada: "Ensalada de lentejas beluga con hinojo y crema de palta",
  tiradito: "Tiradito con leche de tigre de granada y almendras laminadas",
  tigre: "Leche de tigre con pescado y mariscos",

  hot_dishes: "PLATOS CALIENTES",
  locro: "Locro de zapallo con semillas de calabaza",
  queso: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 - con queso de cabra",
  sopa: "Sopa de pescado y mariscos",
  tataki: "Tataki de atún con papas grilladas, cebolla encurtida y crema de Ajipanca",
  pulpo: "Pulpo grillado con yuca frita, espinacas tiernas y causa de pallares",

  cevi_vegano: "Ceviche vegano (verduras y frutas de temporada)",
  cevi_pescado: "Ceviche de pescado",
  cevi_mixto: "Ceviche mixto (con pescado, gambas y calamar)",
  cevi_pulpo: "Ceviche de pulpo y pescado",
  cevi_nikkei: "Ceviche nikkei de atún, palta y algas nori",

  desserts: "POSTRES",
  suspiro: "Suspiro a la limeña (crema de toffee con merengue)",
  sueño: "Sueño de maracuyá (crema de maracuyá, crumble y merengue)",

  drink_title: "BEBIDAS",
  white_wine: "VINO BLANCO",
  red_wine: "VINO TINTO",
  beer: "CERVEZA",
  long_drinks: "COMBINADOS",
  soft_drinks: "REFRESCOS",
  coffee: "CAFÉS Y TÉS",
  cocktails: "CÓCTELES DE PISCO",

  bottle: "botella",
  juices: "Manzana/Naranja/Piña",
  water: "AGUA",
  gas: "Con o sin gas",
  tee: "Surtido de tés",

  no_alcohol: "Lammsbräu sin alcohol",

  
  additives: "Aditivos",
  caffeine: "Cafeína",
  eggwhite: "Clara de huevo",
  colouring: "Colorante",
  sulphites: "Sulfitos",

  // contact page
  data: "Datos",
  write_us: "Escríbenos",
  contact_title: "CONTACTO",
  name: "Nombre",
  name_placeholder: "Escribe tu nombre",
  email: "Correo electrónico",
  email_placeholder: "Escribe tu correo electrónico",
  telephone: "Teléfono",
  telephone_placeholder: "Escribe tu teléfono",
  message: "Mensaje",
  submit: "Enviar",
  confirmation_Message: "Gracias, tu mensaje ha sido enviado.",
};
